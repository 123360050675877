<template>
  <div id="quotes-list">
     
    <!-- tables -->
    <b-card>
      <b-row align-v="center" v-show="statusLoading">
        <div style="width: 100%;height: 400px;">
          <div class="row align-items-center h-100">
            <div class="col text-center" style="color: #828181">
              <b-overlay
                no-wrap
                :show="statusLoading"
                opacity="0.2"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block mb-5 mt-3" />
              <span>Obteniendo información.</span>
            </div>
          </div>
        </div>
      </b-row>
      <b-row v-show="!statusLoading && !errorLoading">
        <b-col xl="6" lg="12" md="12" sm="12">
          <b-form-group label="">
            <b-form-datepicker
              locale="es"
              id="consolidity_ini"
              :max="filtersForm.end"
              placeholder="Inicio"
              :show-decade-nav=true
              :hide-header=true
              :date-format-options="{ month: 'short'}"
              @input="_dateFilters()"
              v-model="filtersForm.start"/>
          </b-form-group>
        </b-col>
        <b-col xl="6" lg="12" md="12" sm="12">
          <b-form-group label="">
            <b-form-datepicker
              locale="es"
              id="consolidity_end"
              :min="filtersForm.start"
              placeholder="Termino"
              :show-decade-nav=true
              :hide-header=true
              :date-format-options="{ month: 'short'}"
              :disabled="dateEndDisable"
              @input="_filtersTable()"
              v-model="filtersForm.end"/>
          </b-form-group>
        </b-col>
      </b-row>
      <div class="scroller mb-1" style="width:100%" v-show="!statusLoading">
        <b-table
            width="100%"
            hover
            striped
            show-empty
            class="position-relative mb-1 mt-2 has-text-centered"
            :items="consolidity"
            :fields="tableColumns"
            primary-key="id"
            empty-text="Datos no Disponibles"> 
        </b-table>
      </div>
      <!-- <b-pagination
        align="right"
        v-model="filtersForm.page"
        hide-goto-end-buttons
        :total-rows="properties.total > 0 ? properties.total : 0"/> -->
    </b-card>
     
    
  </div>
</template>

<script>
  import {mapActions, mapState} from 'vuex' 
  import { BSpinner } from 'bootstrap-vue'

  export default {
    name: 'TableQuotes',
    components: {
      
      BSpinner
    },
    data () {
      return {
        statusLoading: false,
        errorLoading: false,
        dateEndDisable: true,
        filtersForm: {
          start: '',
          end: '',
        }, 
        tableColumns: [ 
          {key: 'origin', sortable: true, label: 'Procedencia'},
          {key: 'quotations', sortable: true, label: 'Cantidad Cotizaciones'},
          {key: 'contacts', sortable: true, label: 'Cantidad Contactos'},
          {key: 'total', sortable: true, label: 'Total'},
          {key: 'percentage', sortable: true, label: '%'}
        ], 
      }
    },
    beforeMount() {
      this._fetchConsolidityQuotes()
    },
    mounted() {  
      this.$root.$on('reload-graphs-home',
        () => { 
          this._fetchConsolidityQuotes()
        }
      ); 
    },
    watch: { 
    },
    computed: {
      ...mapState('quotations',['consolidity']),
    }, 
    methods: {
      ...mapActions('quotations',['fetchConsolidityQuotes']), 
      
      async _fetchConsolidityQuotes() { 
        let self = this;
        this.statusLoading = true;
        await this.fetchConsolidityQuotes(this.filtersForm)
        .finally(() => {
            this.statusLoading = false;
        })
        .catch(error => {
          console.log(error);
        })
      }, 
      _dateFilters(){
        this.filtersForm.end = '';
        this.dateEndDisable = false;
      },
      _filtersTable(){
        this._fetchConsolidityQuotes();
      },
 
    },
  }
</script>

<style scoped>
  .scroller {
      width: 100%;
      overflow-x: auto !important;
  }
  .marginrigth24{
    margin-right: 14px;
  }
</style>
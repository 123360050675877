<template>
  <div id="properties-list">
     
    <!-- tables -->
    <b-card v-if="property_selected === 1">
      <b-row> 
      </b-row>
      <div class="scroller mb-1" style="width:100%">
        <b-overlay :show="overlayForm" opacity="0.5" spinner-small spinner-variant="primary" class="d-inline-block" @hidden="_onHidden()" style="width:100%">
          <b-table
            width="100%"
            hover
            striped
            show-empty
            class="position-relative mb-1 mt-2 has-text-centered"
            :items="properties.data"
            :fields="tableColumns"
            primary-key="id"
            empty-text="Datos no Disponibles"> 
          </b-table>
        </b-overlay>
      </div>
      <b-pagination
        align="right"
        v-model="filtersForm.page"
        hide-goto-end-buttons
        :total-rows="properties.total > 0 ? properties.total : 0"/>
    </b-card>
     
    
  </div>
</template>

<script>
  import {mapActions, mapState, mapGetters} from 'vuex' 
  import ToastificationContent from "@core/components/toastification/ToastificationContent"
  import { BSpinner } from 'bootstrap-vue'

  export default {
    name: 'TableModels',
    components: {
      
      BSpinner
    },
    data () {
      return {
        allSelected:false, 
        test: false,
        sidebar: false,
        propertyData: null,
        overlayForm: false,
        project: '',
        currentPage: 1,
        totalPage: 0,
        project_selected: '',
        property_selected: 1,
        aditional_property_type_selected: 0,
        filtersForm: {
          project: null,
          page: 1,
          identifier: '',
          model: null,
          orientation: null,
          tower: null,
          visible: null,
          real_state_agency: null,
        }, 
        orientationSelect: [
          {id: 'N', name: 'Norte'},
          {id: 'S', name:'Sur'},
          {id: 'E', name:'Oriente'},
          {id: 'W', name:'Poniente'},
          {id: 'NE', name:'Nororiente'},
          {id: 'NW', name:'Norponiente'},
          {id: 'SE', name:'Suroriente'},
          {id: 'SW', name:'Surponiente'},
        ],
        tableColumns: [ 
          {key: 'model', sortable: true, label: 'Modelo'},
          {key: 'quotation_count', sortable: true, label: 'Numero de cotizaciones '},
          {key: 'booking_request_count', sortable: true, label: 'Solicitudes de reservas '},
          {key: 'booking_request_payments', sortable: true, label: 'Pagos de reservas '},
          {key: 'promise_request_count', sortable: true, label: 'Solicitudes de promesas '},
          {key: 'promise_request_payment', sortable: true, label: 'Pagos de promesa '},
          {key: 'units_count', sortable: true, label: 'Unidades totales'},
          {key: 'units_availabe', sortable: true, label: 'Unidades disponibles'},
        ], 
        overlayFormUpload:false,
        project_selected_obj:{}
      }
    },
    beforeMount() {
      if(this.filter_project.project != undefined) {
        this.filtersForm.project = this.filter_project.project 
      }
      this._fetchPropertiesHome(this.filtersForm) 
    },
    mounted() {  
      this.$root.$on('reload-graphs-home',
        (data) => { 
          this.filtersForm.project = data.project; 
          this.filtersForm.real_state_agency = data.real_state_agency;
          this.filtersForm.page = 1;
          this._fetchPropertiesHome(this.filtersForm) 
        }
      ); 
    },
    watch: { 
      filtersForm: {
        handler(filter){ 
          this._fetchPropertiesHome(filter)
        },
        deep: true
      },
    },
    computed: {
      ...mapState('properties',['properties', 'parkinglots', 'warehouses', 'property_select', 'filter_project', 'properties_extra_data']),
    }, 
    methods: {
      ...mapActions('properties',['fetchPropertiesFromHome']), 
       
      async _fetchPropertiesHome(filter) { 
        let self = this;
        this.overlayForm = true;
        await this.fetchPropertiesFromHome(filter)
        .finally(() => {
            self.overlayForm = false;
        })
        .catch(error => {
        })
      }, 
      _onHidden() {
        //this.$refs.button.focus()
      },
 
    },
  }
</script>

<style scoped>
  .scroller {
      width: 100%;
      overflow-x: auto !important;
  }
  .marginrigth24{
    margin-right: 14px;
  }
</style>
<template>
  <div>
    <b-card class="position-static text-center" title="Contactos por Proyecto">
      <b-row v-show="errorLoading">
        <div style="width: 100%;height: 400px;">
          <div class="row align-items-center h-100">
            <div class="col text-center" style="color: #828181">
              <feather-icon icon="AlertTriangleIcon" class="mr-50"/><br>
              <span>No podemos cargar la información.</span>
            </div>
          </div>
        </div>
      </b-row>
      <b-row align-v="center" v-show="statusLoading">
        <div style="width: 100%;height: 400px;">
          <div class="row align-items-center h-100">
            <div class="col text-center" style="color: #828181">
              <b-overlay
                no-wrap
                :show="statusLoading"
                opacity="0.2"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block mb-5 mt-3" />
              <span>Obteniendo información.</span>
            </div>
          </div>
        </div>
      </b-row>
      <b-row v-show="!statusLoading && !errorLoading">
        <b-col xl="6" lg="12" md="12" sm="12">
          <b-form-group label="">
            <b-form-datepicker
              locale="es"
              id="contact_projects_ini"
              :max="filtersForm.end"
              placeholder="Inicio"
              :show-decade-nav=true
              :hide-header=true
              :date-format-options="{ month: 'short'}"
              @input="_dateFilters()"
              v-model="filtersForm.start"/>
          </b-form-group>
        </b-col>
        <b-col xl="6" lg="12" md="12" sm="12">
          <b-form-group label="">
            <b-form-datepicker
              locale="es"
              id="contact_projects_end"
              :min="filtersForm.start"
              placeholder="Termino"
              :show-decade-nav=true
              :hide-header=true
              :date-format-options="{ month: 'short'}"
              :disabled="dateEndDisable"
              @input="_filtersGraphic()"
              v-model="filtersForm.end"/>
          </b-form-group>
        </b-col>
        <b-col xs="12">
          <div class="graphic" ref="chartdiv" />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
  import {mapActions} from 'vuex'
  import * as am4core from '@amcharts/amcharts4/core'
  import * as am4charts from '@amcharts/amcharts4/charts'
  import am4themes_animated from '@amcharts/amcharts4/themes/animated'
  am4core.useTheme(am4themes_animated);

  export default{
    name: 'GraphicContactsProject',
    data() {
      return {
        statusLoading: false,
        errorLoading: false,
        dateStartDisable: true,
        dateEndDisable: true,
        data: [],
        filtersForm: {
          project: '',
          real_state_agency: '',
          type: 'days',
          start: '',
          end: '',
        },
      }
    },
    mounted() {
      this._buildGraphic();
      this.$root.$on('reload-graphs-home',
        (data) => { 
          this.filtersForm.project = data.project;
          this.filtersForm.real_state_agency = data.real_state_agency;
          this._buildGraphic();
        }
      ); 
    },
    destroyed() {
      this._destroyGraphic();
    },
    methods: {
      ...mapActions('dashboard',['graphicContactProject']),
      async _graphicContactProject () {
        this.statusLoading = true
        await this.graphicContactProject(this.filtersForm)
          .then(res => {
            this.data = res.data
            this.errorLoading = false
          }).catch(err => {
            this.errorLoading = true
          }).finally(() => {
            this.statusLoading = false
          })
      },
      async _buildGraphic() {
        let chart = am4core.create(this.$refs.chartdiv, am4charts.PieChart);

        await this._graphicContactProject()

        this.data.map((element) =>{
          element.name = element.project.name;
        } )

        chart.data = this.data

        chart.padding(40, 40, 40, 40);

        let pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "count";
        pieSeries.dataFields.category = "name";
        pieSeries.alignLabels = false;
        pieSeries.labels.template.disabled = true;
        pieSeries.slices.template.stroke = am4core.color("#FFF");
        pieSeries.slices.template.strokeOpacity = 1;

        pieSeries.hiddenState.properties.opacity = 1;
        pieSeries.hiddenState.properties.endAngle = -90;
        pieSeries.hiddenState.properties.startAngle = -90;

        chart.hiddenState.properties.radius = am4core.percent(0);
       },
      _destroyGraphic() {
        if (this.chart) {
          this.chart.dispose();
        }
      },
      _filtersGraphic(){
        this._buildGraphic()
      },
      _dateFilters(){
        this.filtersForm.end = '';
        this.dateEndDisable = false;
      }
    }
  }
</script>

<style>
    .graphic {
        width: 100%;
        height: 550px;
    }
</style>
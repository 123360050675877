<template>
  <div>
    <b-card class="position-static text-center" title="Cotizaciones">
      <b-row v-show="errorLoading">
        <div style="width: 100%;height: 400px;">
          <div class="row align-items-center h-100">
            <div class="col text-center" style="color: #828181">
              <feather-icon icon="AlertTriangleIcon" class="mr-50"/><br>
              <span>No podemos cargar la información.</span>
            </div>
          </div>
        </div>
      </b-row>
      <b-row align-v="center" v-show="statusLoading">
        <div style="width: 100%;height: 400px;">
          <div class="row align-items-center h-100">
            <div class="col text-center" style="color: #828181">
              <b-overlay
                no-wrap
                :show="statusLoading"
                opacity="0.2"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block mb-5 mt-3" />
              <span>Obteniendo información.</span>
            </div>
          </div>
        </div>
      </b-row>
      <b-row v-show="!statusLoading && !errorLoading">
        <b-col xl="4" lg="12" md="12" sm="12">
          <b-form-group label="">
            <v-select v-model="filtersForm.type"
              label="text"
              placeholder="Tipo"
              :options="types"
              :reduce="types => types.id"
              @input="_typeFilters()">
              <span slot="no-options">
                No se encontraron opciones
              </span>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col xl="4" lg="12" md="12" sm="12">
          <b-form-group label="">
            <b-form-datepicker
              locale="es"
              id="quotation_date_ini"
              :max="filtersForm.end"
              placeholder="Inicio"
              :show-decade-nav=true
              :hide-header=true
              :date-format-options="{ month: 'short'}"
              :disabled="dateStartDisable"
              @input="_dateFilters()"
              v-model="filtersForm.start"/>
          </b-form-group>
        </b-col>
        <b-col xl="4" lg="12" md="12" sm="12">
          <b-form-group label="">
            <b-form-datepicker
              locale="es"
              id="quotation_date_end"
              :min="filtersForm.start"
              placeholder="Termino"
              :show-decade-nav=true
              :hide-header=true
              :date-format-options="{ month: 'short'}"
              :disabled="dateEndDisable"
              @input="_filtersGraphic()"
              v-model="filtersForm.end"/>
          </b-form-group>
        </b-col>
        <b-col xs="12">
          <div class="graphic" ref="chartdiv" />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
  import {mapActions} from 'vuex'
  import * as am4core from '@amcharts/amcharts4/core'
  import * as am4charts from '@amcharts/amcharts4/charts'
  import am4themes_animated from '@amcharts/amcharts4/themes/animated'
  am4core.useTheme(am4themes_animated);

  export default{
    name: 'GraphicQuotationsDate',
    data() {
      return {
        statusLoading: false,
        errorLoading: false,
        dateStartDisable: true,
        dateEndDisable: true,
        data: [],
        types: [
          {id: 'days', text: 'Ultimos 5 Días'},
          {id: 'monthly', text: 'Mensual'},
          {id: 'annual', text: 'Anual'}
        ],
        filtersForm: {
          project: '',
          real_state_agency: '',
          type: 'days',
          start: '',
          end: '',
        },
      }
    },
    mounted() {
      this._buildGraphic();
      this.$root.$on('reload-graphs-home',
        (data) => { 
          this.filtersForm.project = data.project;
          this.filtersForm.real_state_agency = data.real_state_agency;
          this._buildGraphic();
        }
      ); 
    },
    destroyed() {
      this._destroyGraphic();
    },
    methods: {
      ...mapActions('dashboard',['graphicQuotationDate']),
      async _graphicQuotationDate () {
        this.statusLoading = true
        await this.graphicQuotationDate(this.filtersForm)
        .then(res => {
          this.data = res.data
          this.errorLoading = false
        }).catch(err => {
          this.errorLoading = true
        }).finally(() => {
          this.statusLoading = false
        })
      },
      async _buildGraphic() {
        let chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart)

        await this._graphicQuotationDate()

        chart.data = this.data

        chart.padding(40, 40, 40, 40);

        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.dataFields.category = "date";
        categoryAxis.renderer.minGridDistance = 60;
        categoryAxis.renderer.grid.template.disabled = true;

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.extraMax = 2;
        valueAxis.title.text = "Cantidad";

        let series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.categoryX = "date";
        series.dataFields.valueY = "count";
        series.tooltipText = "{valueY.value}"
        series.columns.template.strokeOpacity = 0;
        series.columns.template.column.cornerRadiusTopRight = 10;
        series.columns.template.column.cornerRadiusTopLeft = 10;
        series.clustered = false;

        let labelBullet = series.bullets.push(new am4charts.LabelBullet());
        labelBullet.label.verticalCenter = "bottom";
        labelBullet.label.dy = -10;
        labelBullet.label.text = "{values.valueY.workingValue.formatNumber('#.')}";

        chart.zoomOutButton.disabled = true;
        chart.responsive.enabled = true;

        series.columns.template.adapter.add("fill", function (fill, target) {
          return chart.colors.getIndex(target.dataItem.index);
        });
      },
      _destroyGraphic() {
        if (this.chart) {
          this.chart.dispose();
        }
      },
      _filtersGraphic(){
        this._buildGraphic()
      },
      _typeFilters(){ 
        if(!this.filtersForm.type){
          this.filtersForm.type = 'days';
        }  
        this.filtersForm.start = null;
        this.filtersForm.end = null;
        this._buildGraphic();
        this.dateStartDisable = this.filtersForm.type == 'days';
        this.dateEndDisable = true;
      },
      _dateFilters(){
        this.filtersForm.end = '';
        this.dateEndDisable = false;
      }
    }
  }
</script>

<style>
  .graphic {
    width: 100%;
    height: 550px;
  }
</style>
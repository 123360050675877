<template>
  <div>
    <b-card class="position-static text-center" title="Negocios">
      <b-row v-show="errorLoading">
        <div style="width: 100%;height: 400px;">
          <div class="row align-items-center h-100">
            <div class="col text-center" style="color: #828181">
              <feather-icon icon="AlertTriangleIcon" class="mr-50"/><br>
              <span>No podemos cargar la información.</span>
            </div>
          </div>
        </div>
      </b-row>
      <b-row align-v="center" v-show="statusLoading">
        <div style="width: 100%;height: 400px;">
          <div class="row align-items-center h-100">
            <div class="col text-center" style="color: #828181">
              <b-overlay
                no-wrap
                :show="statusLoading"
                opacity="0.2"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block mb-5 mt-3" />
              <span>Obteniendo información.</span>
            </div>
          </div>
        </div>
      </b-row>
      <b-row v-show="!statusLoading && !errorLoading">
        <b-col xl="4" lg="12" md="12" sm="12" v-show="!is_admin">
          <b-form-group label="">
            <v-select v-model="filtersForm.project"
              label="name"
              placeholder="Proyecto"
              :options="projects"
              :reduce="projects => projects.id"
              @input="_typeFilters()" 
              >
              <span slot="no-options">
                No se encontraron opciones
              </span>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col :xl="cols_xl" :lg="cols_lg" :md="cols_md" :sm="cols_sm" >
          <b-form-group label="">
            <b-form-datepicker
              locale="es"
              id="business_ini"
              :max="filtersForm.end"
              placeholder="Inicio"
              :show-decade-nav=true
              :hide-header=true
              :date-format-options="{ month: 'short'}"
              :disabled="dateStartDisable"
              @input="_dateFilters()"
              v-model="filtersForm.start"/>
          </b-form-group>
        </b-col>
        <b-col :xl="cols_xl" :lg="cols_lg" :md="cols_md" :sm="cols_sm" >
          <b-form-group label="">
            <b-form-datepicker
              locale="es"
              id="business_end"
              :min="filtersForm.start"
              placeholder="Termino"
              :show-decade-nav=true
              :hide-header=true
              :date-format-options="{ month: 'short'}"
              @input="_filtersGraphic()"
              :disabled="dateEndDisable"
              v-model="filtersForm.end"/>
          </b-form-group>
        </b-col> 
      </b-row>
      <b-row>
        <b-col xs="12">
          <div class="graphic" ref="chartdiv" />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'
  import * as am4core from '@amcharts/amcharts4/core'
  import * as am4charts from '@amcharts/amcharts4/charts'
  import am4themes_animated from '@amcharts/amcharts4/themes/animated'
  am4core.useTheme(am4themes_animated);

  export default{
    name: 'GraphicSales',
    data() {
      return {
        is_admin:false,
        statusLoading: false,
        errorLoading: false,
        dateStartDisable: true,
        dateEndDisable: true,
        data: [],
        filtersForm: {
          project: '',
          real_state_agency: '',
          start: '',
          end: '',
        },
        cols_xl:4,
        cols_lg:12,
        cols_md:12,
        cols_sm:12
      }
    },
    mounted() {
      this.is_admin = this.$PermissionHelper.getRole().toLowerCase() == 'admin';
      if(this.is_admin){
        this.dateStartDisable = false;
        this.dateEndDisable = false;
        this.cols_xl = 6;
      }

      this._buildGraphic();
      this.$root.$on('reload-graphs-home',
        (data) => { 
          this.filtersForm.project = data.project;
          this.filtersForm.real_state_agency = data.real_state_agency;
          this._buildGraphic();
        }
      ); 
    },
    destroyed() {
      this._destroyGraphic();
    },
    computed: {
      ...mapGetters('profile', ['projects']),
    },
    methods: {
      ...mapActions('dashboard',['graphicBusiness']),
      async _graphicBusiness () {
        this.statusLoading = true
        await this.graphicBusiness(this.filtersForm)
          .then(res => {
            this.data = res.data
            this.errorLoading = false
          }).catch(err => {
            this.errorLoading = true
          }).finally(() => {
            this.statusLoading = false
          })
      },
      async _buildGraphic() {
        let chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);

        await this._graphicBusiness()

        chart.padding(40, 40, 40, 40);
        chart.data = this.data

        let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.dataFields.category = "name";
        categoryAxis.renderer.minGridDistance = 1;
        categoryAxis.renderer.inversed = true;
        categoryAxis.renderer.grid.template.disabled = true;

        let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;

        let series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.categoryY = "name";
        series.dataFields.valueX = "count";
        series.tooltipText = "{valueX.value}"
        series.columns.template.strokeOpacity = 0;
        series.columns.template.column.cornerRadiusBottomRight = 5;
        series.columns.template.column.cornerRadiusTopRight = 5;

        let labelBullet = series.bullets.push(new am4charts.LabelBullet())
        labelBullet.label.horizontalCenter = "left";
        labelBullet.label.dx = 10;
        labelBullet.label.text = "{values.valueX.workingValue.formatNumber('#')}";
        labelBullet.locationX = 1;

        series.columns.template.adapter.add("fill", function(fill, target){
          return chart.colors.getIndex(target.dataItem.index);
        });

        categoryAxis.sortBySeries = series;
      },
      _destroyGraphic() {
        if (this.chart) {
          this.chart.dispose();
        }
      },
      _filtersGraphic(){
        this._buildGraphic()
      },
      _typeFilters(){
        if(this.filtersForm.project !== ''){
          this.dateStartDisable = false;
        }
      },
      _dateFilters(){
        this.filtersForm.end = '';
        this.dateEndDisable = false;
      }
    }
  }
</script>

<style>
  .graphic {
    width: 100%;
    height: 550px;
  }
</style>
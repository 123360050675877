<template>
  <div id="home">  
    <b-row>
      <b-col xl="12" md="12" v-if="is_admin" class="mt-low-2">
        <b-card no-body class="mb-0">  
          <div class="m-2">
            <b-row> 
              <b-col xl="6" sm="12">
                <b-form-group label="Inmobiliarias" label-for="inmobiliarias">
                  <v-select
                    v-model="filtersForm.real_state_agency"
                    label="name"
                    placeholder="Selecciona una inmobiliaria"
                    :options="realStateAgencies"
                    :reduce="realStateAgencies => realStateAgencies.id">
                    <span slot="no-options">
                      No se encontraron opciones
                    </span>
                  </v-select>
                </b-form-group>
                
              </b-col>  

              <b-col xl="6" sm="12">
                <b-form-group label="Proyectos" label-for="projects">
                  <v-select
                    v-model="filtersForm.project"
                    label="name"
                    placeholder="Selecciona un Proyecto"
                    :options="filteredProjects"
                    :reduce="filteredProjects => filteredProjects.id"
                    :disabled="filtersForm.real_state_agency == '' || filtersForm.real_state_agency == null"
                    >
                    <span slot="no-options">
                      No se encontraron opciones
                    </span>
                  </v-select>
                </b-form-group>
              </b-col>   
              
            </b-row>
          </div>


        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col xl="6" md="12">
        <graphic-quotations-date></graphic-quotations-date>
      </b-col>
      <b-col xl="6" md="12">
        <graphic-quotations-project></graphic-quotations-project>
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="6" md="12">
        <graphic-contacts></graphic-contacts>
      </b-col>
      <b-col xl="6" md="12">
        <graphic-contacts-project></graphic-contacts-project>
      </b-col>
    </b-row> 
    <b-row>
      <b-col xl="6" md="12">
        <graphic-bookings-date></graphic-bookings-date>
      </b-col>
      <b-col xl="6" md="12">
        <graphic-promises-date></graphic-promises-date>
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="6" md="12">
        <graphic-business></graphic-business>
      </b-col>
      <b-col xl="6" md="12">
        <graphic-sales></graphic-sales>
      </b-col>
    </b-row> 

    <b-row v-if="is_admin">
      <b-col xl="12" md="12">
        <table-quotes></table-quotes>
      </b-col> 
    </b-row>
    
    <b-row v-if="is_admin">
      <b-col xl="12" md="12">
        <table-models></table-models>
      </b-col> 
    </b-row>
    

  </div>
</template>

<script>
  import {mapActions, mapState, mapGetters} from 'vuex'
  import GraphicBookingsDate from './components/GraphicBookingsDate'
  import GraphicBusiness from './components/GraphicBusiness'
  import GraphicPromisesDate from './components/GraphicPromisesDate'
  import GraphicQuotationsDate from './components/GraphicQuotationsDate'
  import GraphicQuotationsProject from './components/GraphicQuotationsProject'
  import GraphicSales from './components/GraphicSales'
  import GraphicContacts from './components/GraphicContacts'
  import GraphicContactsProject from './components/GraphicContactsProject'
  import TableModels from './components/TableModels'
  import TableQuotes from './components/TableQuotes'

  export default{
    name: 'HomeView',
    components: {
      GraphicBookingsDate,
      GraphicBusiness,
      GraphicPromisesDate,
      GraphicQuotationsDate,
      GraphicQuotationsProject,
      GraphicContacts,
      GraphicContactsProject,
      GraphicSales,
      TableModels,
      TableQuotes
    },
    data() {
      return {
        is_admin:false,
        filtersForm: {
          project: '',
          real_state_agency: '',
          page: 1
        },
        reloadingGraphs:false,
        initialProjects:[],
        filteredProjects:[]
      }
    },
    watch:{
      "filtersForm.real_state_agency": function (val) { 
        let self = this;
        if(val == null){
          this.filteredProjects = this.initialProjects;
        }else{
          this.filteredProjects = [];
          this.initialProjects.forEach( function(valor, indice, array) {
              if(valor.realstateagency.id == val){
                self.filteredProjects.push(valor)
              }
          });
        }

        this.reloadGraphs()
      },
      "filtersForm.project": function (val) { 
        this.reloadGraphs()
      },
    },
    computed: {      
      ...mapGetters('profile',['projects','realStateAgencies']),
    },
    beforeMount() {
      let self = this;
      this.is_admin = this.$PermissionHelper.getRole().toLowerCase() == 'admin';

      this.fetchRealStateAgenciesNoPagination()
      this.fetchProjectsRelatedToMe()
      .then(res => { 
        self.initialProjects = res.data.projectsrelatedtome;
        self.filteredProjects = self.initialProjects;
      });

      this.fetchLocations()
      this.fetchProjectStatus()
      this.fetchSubsidyTypes()
      this.fetchPlanAdittionalTypes()
      this.fetchProjectStageTypes()
    },
    methods: {
      ...mapActions('profile', ['fetchRealStateAgenciesNoPagination','fetchRealStateAgencies','fetchProjectsRelatedToMe','fetchLocations','fetchProjectStatus','fetchSubsidyTypes','fetchPlanAdittionalTypes','fetchProjectStageTypes']),

      reloadGraphs(){
        this.reloadingGraphs = true; 
        this.$root.$emit('reload-graphs-home',this.filtersForm); 
        this.reloadingGraphs = false;
      }
    },
  }
</script>

<style scoped>
.fix-margin-top{
  margin-top: 20px;
}
.mt-low-2{
  margin-bottom: 1.5rem;
}

</style>